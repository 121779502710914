<template>
	<div>
		<transition name="fade">
			<Message v-if="error" :message="error" type="error" />
		</transition>

		<Message v-if="loading" message="loading" type="info" />

		<template v-else>
			<InputField
				v-model="newName"
				name="Group Name"
				:disabled="!!status"
			/>

			<GroupEditor
				v-model="group"
				:domains="domains"
			/>

			<div class="navigation">
				<Button @click="back">
					Back
				</Button>

				<Button :disabled="!!status" @click="setGroup">
					Submit
				</Button>
			</div>
		</template>
	</div>
</template>

<script>
import api from "@/api";

import InputField	from "@/components/InputField.vue";
import Button		from "@/components/Button.vue";
import Message		from "@/components/Message.vue";
import GroupEditor	from "@/components/GroupEditor.vue";

export default {
	name: "Group",

	components: {
		InputField,
		Button,
		Message,
		GroupEditor
	},

	props: {
		name: {
			type: String,
			required: false,
			default: ""
		}
	},

	data () {
		return {
			error: undefined,
			status: undefined,
			loading: true,

			newName: "",

			group: {},

			domains: []
		};
	},

	async beforeMount () {
		this.newName = this.name;

		try {
			// Get the group.
			const groups = await api.getGroups();

			if (groups[this.name])
				delete groups[this.name].static;

			this.group = groups[this.name] || {};

			// Get the domains.
			this.domains = await api.getDomains();

		} catch (error) {
			this.error = error.message;
			console.error(error);
		}

		// Update status
		this.loading = false;
	},

	methods: {
		async setGroup () {
			this.status = "Updating";

			try {
				await  api.setGroup(this.newName, this.group);

				this.$router.push({
					path: "/admin/groups"
				});
			} catch (error) {
				this.error = error.message;
				console.error(error);
			}

			this.status = undefined;
		},

		back () {
			this.$router.push({
				name: "Groups"
			});
		}
	}
};
</script>

<style scoped>
.navigation {
	display: flex;
}

.navigation > * {
	width: 40%;
	margin: auto;
}
</style>
