<template>
	<div>
		<InputFieldButton
			v-if="!domains.length"
			v-model="service"
			name="Service"
			placeholder="jwt.io"
			:disabled="disabled"
			@submit="add"
		/>

		<DropDown
			v-if="domains.length"
			v-model="service"
			name="Service"
			placeholder="Please Choose..."
			:options="uniqueDomains"
			:disabled="disabled"
			@update:modelValue="add"
		/>

		<Card v-for="serviceName of Object.keys(modelValue)" :key="serviceName">
			<template #header>
				{{ serviceName }}
			</template>

			<template #sub-header>
				<InputFieldButton
					class="group-input"
					name="Group"
					placeholder="access"
					:disabled="disabled"
					@submit="value => addGroup(serviceName, value)"
				/>
			</template>

			<template #default>
				<ButtonList
					class="buttons"
					:disabled="disabled"
					:model-value="modelValue[serviceName]"
					@click="(value, index) => removeGroup(serviceName, index)"
				/>
			</template>

			<template #footer>
				<Button
					class="delete"
					@click="() => removeService(serviceName)"
				>
					Delete
				</Button>
			</template>
		</Card>
	</div>
</template>

<script>
import InputFieldButton from "./InputFieldButton";
import DropDown 		from "./DropDown";
import Card 			from "./Card";
import Button			from "./Button";
import ButtonList		from "./ButtonList";

export default {
	name: "GroupEditor",

	components: {
		InputFieldButton,
		DropDown,
		Card,
		Button,
		ButtonList
	},

	props: {
		modelValue: {
			type: Object,
			required: true
		},

		disabled: {
			type: Boolean,
			required: false,
			default: false
		},

		domains: {
			type: Array,
			required: true
		}
	},

	emits: ["update:modelValue"],

	data () {
		return {
			service: ""
		};
	},

	computed: {
		uniqueDomains () {
			const usedDomains = Object.keys(this.modelValue);

			return ["default", ...this.domains]
				.filter(domain => !usedDomains.includes(domain));
		}
	},

	methods: {
		add () {
			if (!this.service)
				return;

			const newObj = {...this.modelValue};

			newObj[this.service] = [];

			this.$emit("update:modelValue", newObj);
			this.service = "";
		},

		addGroup (service, group) {
			if (!service || !group)
				return;

			const newObj = {...this.modelValue};

			if (!newObj[service])
				return;

			newObj[service].push(group);
			this.$emit("update:modelValue", newObj);
		},

		removeService (service) {
			if (!service)
				return;

			const newObj = {...this.modelValue};

			delete newObj[service];

			this.$emit("update:modelValue", newObj);
		},

		removeGroup (service, index) {
			if (!service)
				return;

			const newObj = {...this.modelValue};

			if (!newObj[service])
				return;

			newObj[service].splice(index, 1);

			this.$emit("update:modelValue", newObj);
		}
	}
};
</script>

<style scoped>
.group-input {
	padding: 25px;
	padding-bottom: 0px;
}

.buttons {
	padding-bottom: 10px;
}

.delete {
	color: white;
	background-color: #f66;
	width: 38%;
	margin-left: auto;
	margin-right: auto;
}

.delete:hover {
	color: white;
	background-color: #ff8080 !important;
}
</style>
