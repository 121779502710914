<template>
	<div class="container">
		<h3>
			<slot name="header" />
		</h3>

		<div class="sub-header">
			<slot name="sub-header" />
		</div>

		<div class="content">
			<slot />
		</div>

		<div class="footer">
			<slot name="footer" />
		</div>
	</div>
</template>

<script>
export default {
	name: "Card"
};
</script>

<style scoped>
.container {
	border: 1px solid #d9d9d9;
	margin: 10px;
	box-shadow: 1px 1px 5px #e6e6e6;
}

h3 {
	text-align: left;
	margin: 0px;
	padding-left: 20px;
	padding-top: 20px;
}

.content {
	padding-left: 20px;
}

.footer {
	text-align: center;
	padding: 5px;
	display: flex;
}
</style>
