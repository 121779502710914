<template>
	<div>
		<label v-if="name" :for="safeName">
			{{ name }}<span v-if="required">*</span>
		</label>

		<select
			:id="safeName"
			:disabled="disabled"
			:value="modelValue"
			@input="$emit('update:modelValue', $event.target.value)"
		>
			<option
				v-if="placeholder"
				value=""
				disabled
				selected
				hidden
			>
				{{ placeholder }}
			</option>

			<option
				v-for="(option, index) of options"
				:key="index"
			>
				{{ option }}
			</option>
		</select>
	</div>
</template>

<script>
export default {
	name: "DropDown",

	props: {
		name: {
			type: String,
			required: false,
			default: ""
		},

		options: {
			type: Array,
			required: true
		},

		modelValue: {
			type: String,
			required: false,
			default: ""
		},

		required: {
			type: Boolean,
			required: false,
			default: false
		},

		disabled: {
			type: Boolean,
			required: false,
			default: false
		},

		placeholder: {
			type: String,
			required: false,
			default: ""
		}
	},

	emits: ["update:modelValue"],

	computed: {
		safeName () {
			return this.name.toLowerCase().replace(/[^\w]/gi, "");
		}
	}
};
</script>

<style scoped>
select {
	display: block;
	margin-bottom: 10px;
	width: 100%;
	box-sizing: border-box;
	padding: 10px 0;
	font-size: 12pt;
	background-color: white;
	border-radius: 6px;
	border: 1px solid #999;


	/* Arrow css */
	-webkit-appearance: none;
	-moz-appearance: none;
	background: transparent;
	background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
	background-repeat: no-repeat;
	background-position-x: 100%;
	background-position-y: 5px;
	border: 1px solid #dfdfdf;
	margin-right: 2rem;
}

label {
	color: #999;
}
</style>
